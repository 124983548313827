import { forwardRef, HTMLAttributes } from "react"

import { create } from "src/helpers/bem"

import styles from "./Card.module.scss"

const bem = create(styles, "Card")

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  className?: string
  selected?: boolean
  noMargin?: boolean
  variant: "primary" | "secondary" | "tertiary" | "info"
}

/**
 * A component without any semantic meaning that visually wraps other components and contents to accentuate them. A card can have different states like highlighted and selected. A card has no inner spacing (padding). To add spacing, specify this by the styling context where the card component is used.
 */
export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      variant,
      selected = false,
      noMargin = false,
      ...attributes
    },
    ref,
  ) => {
    const modifiers = {
      [`is-${variant}`]: true,
      [`is-selected`]: selected,
      "no-margin": noMargin,
    }

    return (
      <div
        ref={ref}
        className={bem(undefined, modifiers, className)}
        {...attributes}
      >
        {children}
      </div>
    )
  },
)

Card.displayName = "Card"
