import { FC } from "react"

import { HUAUFootnote } from "../../../flow/common/categoryFootnote"
import { hasAdditionalServices } from "../../helpers/hasAdditionalServices"
import { CategoryAdditionals } from "../categoryAdditionals"
import { CategoryServicesWithCheckboxes } from "../categoryServices"
import { CategoryWithPrice } from "../categoryWithPrice"
import { InspectionDetails } from "../inspectionDetails"
import { Card } from "src/components/common/card"
import {
  AdditionalService,
  Service,
} from "src/helpers/autoservice-core-backend/types"
import { Category } from "src/helpers/autoservice-core-backend/types"

import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { getReplacementCarDescription } from "src/helpers/getReplacementCarDescription"
import { hasReplacementCarAdditionalService } from "src/helpers/isReplacementCarSelected"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import { useServiceFlowData } from "src/states/serviceFlow"
import { ServiceCategory } from "src/states/serviceFlow/types"

import styles from "./DefaultCategory.module.scss"

const bem = create(styles, "DefaultCategory")

export type DefaultCategoryProps = {
  category: Category
  serviceDisabled?: boolean
  selectedCategoryData: ServiceCategory
  onServiceToggle: (service: Service) => void
  onAdditionalServiceToggle: (
    additionalService: AdditionalService,
    checked: boolean,
  ) => void
}

export const DefaultCategory: FC<DefaultCategoryProps> = ({
  category,
  serviceDisabled,
  selectedCategoryData,
  onServiceToggle,
  onAdditionalServiceToggle,
}) => {
  const { detailedGarage } = useServiceFlowData()
  const { isPitstopInspectionFlow } = useInspectionFlows()

  const isInspectionSelected = checkServiceType.isInspectionService(
    category.internalServiceCategoryId,
  )

  const isHUAUService = checkServiceType.isHUAUService(
    category.internalServiceCategoryId,
  )

  const replacementCarAdditionalServiceIndex =
    category.additionalServices.findIndex(hasReplacementCarAdditionalService)

  category.additionalServices[
    replacementCarAdditionalServiceIndex
  ].description = getReplacementCarDescription(
    detailedGarage?.garage,
    isPitstopInspectionFlow,
  )

  const hasAdditionals = hasAdditionalServices(category.additionalServices)
  const hasMultipleServices = category.services.length > 1

  return (
    <div className={bem()}>
      <CategoryWithPrice
        serviceDisabled={serviceDisabled}
        category={category}
      />

      {hasMultipleServices && (
        <CategoryServicesWithCheckboxes
          category={category}
          selectedService={selectedCategoryData.service}
          onServiceToggle={onServiceToggle}
        />
      )}

      {isHUAUService && (
        <div>
          <HUAUFootnote />
        </div>
      )}

      {hasAdditionals && (
        <CategoryAdditionals
          categoryName={category.title}
          additionalServices={category.additionalServices}
          selectedAdditionalServices={selectedCategoryData.additionalServices}
          onAdditionalServiceToggle={onAdditionalServiceToggle}
          packageAdditionalServiceIds={
            selectedCategoryData.service?.packageAdditionalServiceIds ?? []
          }
        />
      )}

      {isInspectionSelected && (
        <Card variant="info">
          <InspectionDetails />
        </Card>
      )}
    </div>
  )
}
