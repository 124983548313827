import { FC } from "react"

import { CategoryServiceItem } from "../categoryServices"
import { Icon, IconName } from "src/components/common/icon"

import { ToggleItem, ToggleSwitch } from "src/components/common/toggleSwitch"

import { Category, Service } from "src/helpers/autoservice-core-backend/types"

import { create } from "src/helpers/bem"

import styles from "./CategoryServicesToggle.module.scss"
const bem = create(styles, "CategoryServicesToggle")

export type CarSide = "top" | "bottom" | "complete"

export type CategoryServicesToggleProps = {
  category: Category
  servicesToggleItems: ToggleItem[]
  selectedService?: Service
  onServiceToggle: (service: Service) => void
  serviceIcon: IconName
  selectedCarSide?: CarSide
}

export const CategoryServicesToggle: FC<CategoryServicesToggleProps> = ({
  category,
  selectedService,
  servicesToggleItems,
  onServiceToggle,
  serviceIcon,
  selectedCarSide,
}) => {
  const { services } = category

  const recommended = selectedCarSide === "complete"

  const renderCarPart = (iconSide: CarSide) => {
    const isSelected =
      selectedCarSide === "complete" || iconSide === selectedCarSide

    return (
      <div className={bem("car__service")}>
        <Icon
          name={serviceIcon}
          className={bem("car__service-icon", {
            "is-selected": isSelected,
          })}
        />
      </div>
    )
  }

  const findServiceById = (id: string) =>
    services.find((service) => service.internalServiceId === id)

  const onValueChange = (id: string) => {
    const newSelectedService = findServiceById(id)

    if (newSelectedService) {
      onServiceToggle(newSelectedService)
    }
  }

  return (
    <div className={bem()}>
      <ToggleSwitch
        type="single"
        variant="secondary"
        items={servicesToggleItems}
        value={selectedService?.internalServiceId}
        onValueChange={onValueChange}
      />

      <div className={bem("car")}>
        <div className={bem("car__side")}>
          {renderCarPart("top")}
          {renderCarPart("bottom")}
        </div>

        <Icon name="carTopView" className={bem("car__icon")} />

        <div className={bem("car__side")}>
          {renderCarPart("top")}
          {renderCarPart("bottom")}
        </div>
      </div>

      <CategoryServiceItem
        service={selectedService}
        handleServiceToggle={onServiceToggle}
        recommended={recommended}
      />
    </div>
  )
}
