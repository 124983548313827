import { FC } from "react"

import { hasAdditionalServices } from "../../helpers/hasAdditionalServices"
import { CategoryAdditionals } from "../categoryAdditionals"
import { CategoryServicesToggle, CarSide } from "../categoryServicesToggle"
import { CategoryWithPrice } from "../categoryWithPrice"
import {
  AdditionalService,
  Service,
} from "src/helpers/autoservice-core-backend/types"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import { ServiceCategory } from "src/states/serviceFlow/types"

import styles from "./DefaultCategory.module.scss"

const bem = create(styles, "DefaultCategory")

export type ShockAbsorberCategoryProps = {
  category: Category
  selectedCategoryData: ServiceCategory
  onServiceToggle: (service: Service) => void
  onAdditionalServiceToggle: (
    additionalService: AdditionalService,
    checked: boolean,
  ) => void
}

export const ShockAbsorberCategory: FC<ShockAbsorberCategoryProps> = ({
  category,
  selectedCategoryData,
  onServiceToggle,
  onAdditionalServiceToggle,
}) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.shockAbsorber

  const hasAdditionals = hasAdditionalServices(category.additionalServices)
  const selectedService = selectedCategoryData.service

  const servicesData: { [key: string]: { title: string; carSide: CarSide } } = {
    SHOCK_ABSORBER_CHANGE_BOTH: {
      title: translations.toggleItems.complete,
      carSide: "complete",
    },
    SHOCK_ABSORBER_CHANGE_FRONT: {
      title: translations.toggleItems.front,
      carSide: "top",
    },
    SHOCK_ABSORBER_CHANGE_BACK: {
      title: translations.toggleItems.back,
      carSide: "bottom",
    },
  }

  const selectedCardSide =
    servicesData[selectedService?.internalServiceId ?? ""]?.carSide

  const servicesToggleItems = category.services.map((service) => ({
    value: service.internalServiceId ?? "",
    children: servicesData[service.internalServiceId ?? ""]?.title ?? "",
  }))

  return (
    <div className={bem()}>
      <CategoryWithPrice category={category} />

      <h2 className={bem("title")}>{translations.title}</h2>

      <div className={bem("variants")}>
        <h3 className={bem("subtitle")}>{translations.selectArea}</h3>

        <CategoryServicesToggle
          category={category}
          servicesToggleItems={servicesToggleItems}
          selectedService={selectedService}
          onServiceToggle={onServiceToggle}
          serviceIcon="stossdaempferwechsel"
          selectedCarSide={selectedCardSide}
        />
      </div>

      {hasAdditionals && (
        <CategoryAdditionals
          categoryName={category.title}
          additionalServices={category.additionalServices}
          selectedAdditionalServices={selectedCategoryData.additionalServices}
          onAdditionalServiceToggle={onAdditionalServiceToggle}
        />
      )}
    </div>
  )
}
