import { FC } from "react"

import { Category, Service } from "src/helpers/autoservice-core-backend/types"

import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { useTranslation } from "src/hooks/translation"

import { CategoryServiceItemWithCheckbox } from "./CategoryServiceItemWithCheckbox"
import styles from "./CategoryServicesWithCheckboxes.module.scss"
import { HuAuForElectricVehicle } from "./huAuForElectricVehicle"

const bem = create(styles, "CategoryServicesWithCheckboxes")

export type CategoryServicesWithCheckboxesProps = {
  category: Category
  selectedService?: Service
  disabled?: boolean
  onServiceToggle: (service: Service) => void
  getIsSelected?: (id: string | undefined) => void
}

export const CategoryServicesWithCheckboxes: FC<
  CategoryServicesWithCheckboxesProps
> = ({
  category,
  selectedService,
  disabled,
  onServiceToggle,
  getIsSelected,
}) => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.service.categoryCard

  const { services, internalServiceCategoryId } = category

  const isHUAUService = checkServiceType.isHUAUService(
    category.internalServiceCategoryId,
  )

  return (
    <div className={bem()}>
      <h2 className={bem("title")}>{translations.servicesTitle}</h2>

      {services.map((service, index) => (
        <CategoryServiceItemWithCheckbox
          key={service.internalServiceId}
          internalServiceCategoryId={internalServiceCategoryId}
          service={service}
          isSelected={
            getIsSelected?.(service.internalServiceId) ||
            selectedService?.internalServiceId === service.internalServiceId
          }
          disabled={disabled}
          recommended={index === 0}
          handleServiceToggle={onServiceToggle}
        />
      ))}
      {isHUAUService && <HuAuForElectricVehicle />}
    </div>
  )
}
