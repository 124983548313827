import { FC, forwardRef, PropsWithChildren } from "react"

import { create } from "src/helpers/bem"

import styles from "./Radio.module.scss"

const bem = create(styles, "Radio")

export type RadioClassNames = {
  container?: string
  label?: string
  field?: string
}

export type RadioProps = PropsWithChildren<{
  id: string
  name?: string
  classNames?: RadioClassNames
  error?: string
  disabled?: boolean
  checked?: boolean
  onChange?: () => void
}>

export const Radio: FC<RadioProps> = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      id,
      classNames,
      error,
      disabled,
      checked,
      onChange,
      ...attributes
    },
    ref,
  ) => {
    const hasError = !!error

    const modifiers = {
      "is-disabled": !!disabled,
      "has-error": hasError,
    }

    return (
      <div className={bem(undefined, undefined, classNames?.container)}>
        <input
          className={bem("field", modifiers, classNames?.field)}
          id={id}
          ref={ref}
          type="radio"
          value={checked ? "true" : "false"}
          aria-describedby={hasError ? `${id}-error` : undefined}
          data-checked={!!checked}
          disabled={disabled}
          checked={checked}
          onChange={() => onChange?.()}
          data-valid={!error}
          {...attributes}
        />

        <label
          className={bem("label", modifiers, classNames?.label)}
          htmlFor={id}
        >
          {children}
        </label>

        {error && (
          <small role="alert" id={`${id}-error`} className={bem("error")}>
            {error}
          </small>
        )}
      </div>
    )
  },
)

Radio.displayName = "Radio"
