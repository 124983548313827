import { FC } from "react"

import { Tag } from "src/components/common/tag"

import { ContentfulRichText } from "src/components/contentful/components/ContentfulRichText"
import { Service } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { useTranslation } from "src/hooks/translation"
import { formatters } from "src/translations/formatters"

import styles from "./CategoryServiceItemContent.module.scss"

const bem = create(styles, "CategoryServiceItemContent")

export type CategoryServiceItemContentProps = {
  service: Service
  recommended?: boolean
}

export const CategoryServiceItemContent: FC<
  CategoryServiceItemContentProps
> = ({ service, recommended }) => {
  const { messages, formatMessage } = useTranslation()
  const translations = messages.pages.services.flow.service.categoryCard
  const priceTranslation = messages.pages.services.flow.services.price

  const { description, price, title } = service

  if (!service) {
    return null
  }

  const isWheelChangePromotion =
    checkServiceType.isWheelChangePromotionService(service)

  return (
    <>
      <div className={bem()}>
        <h3 className={bem("title")}>{title}</h3>

        {description && (
          <ContentfulRichText
            className={bem("description")}
            content={description}
          />
        )}

        {price && (
          <div className={bem("price-wrapper")}>
            <p className={bem("price")}>
              {formatMessage(priceTranslation, {
                price: formatters.currency.format(price),
              })}
            </p>

            {recommended && !isWheelChangePromotion && (
              <Tag variant="emphasized">{translations.recommended}</Tag>
            )}
          </div>
        )}
      </div>
    </>
  )
}
