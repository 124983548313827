import { FC } from "react"

import { ToggleSwitch } from "src/components/common/toggleSwitch"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./BrakeDiscAndPadToggle.module.scss"
const bem = create(styles, "BrakeDiscAndPadToggle")

export type BrakeDiscAndPadToggleProps = {
  title: string
  value?: string
  onValueChange: (selectedValue: string) => void
}

export const BrakeDiscAndPadToggle: FC<BrakeDiscAndPadToggleProps> = ({
  title,
  value,
  onValueChange,
}) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.brakesChange

  const categoriesNames: { [key: string]: string } = {
    BRAKE_DISC_CHANGE: translations.categories.discAndPads,
    BRAKE_PAD_CHANGE: translations.categories.onlyPads,
  }

  const toggleItems = Object.keys(categoriesNames).map((key) => ({
    value: key,
    children: categoriesNames[key],
  }))

  return (
    <div className={bem()}>
      <h3 className={bem("title")}>{title}</h3>
      <ToggleSwitch
        type="single"
        variant="secondary"
        className={bem("categories")}
        items={toggleItems}
        value={value}
        onValueChange={onValueChange}
      />
    </div>
  )
}
