import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react"

import { scrollToElement } from "src/helpers/scrollToElement"

type ServiceConfiguratorValidationContextType = {
  isValid?: boolean
  setIsValid?: (isValid: boolean) => void
  showError?: boolean
  validate?: () => boolean
}

const Context = createContext<ServiceConfiguratorValidationContextType>({})

type ServiceConfiguratorValidationContextProps = PropsWithChildren

export const ServiceConfiguratorValidationContext: FC<
  ServiceConfiguratorValidationContextProps
> = ({ children }) => {
  const [isValid, setIsValid] = useState(true)
  const [showError, setShowError] = useState(false)

  function validate() {
    setShowError(!isValid)

    if (!isValid) {
      setTimeout(() => {
        scrollToElement(`[data-error="true"]`)
      }, 300)
    }

    return isValid
  }

  return (
    <Context.Provider value={{ isValid, setIsValid, showError, validate }}>
      {children}
    </Context.Provider>
  )
}

export const useServiceConfiguratorValidationContext =
  (): ServiceConfiguratorValidationContextType => {
    return useContext(Context)
  }
