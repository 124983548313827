import {
  Children,
  cloneElement,
  FC,
  PropsWithChildren,
  ReactElement,
} from "react"

import { create } from "src/helpers/bem"

import styles from "./Timeline.module.scss"
import { TimelineItem, TimelineItemProps } from "./TimelineItem"

const bem = create(styles, "Timeline")

export type CompoundTimelineTypes = {
  Item: FC<TimelineItemProps>
}

export type TimelineProps = PropsWithChildren<{
  title?: string
  className?: string
}>

export const Timeline: FC<TimelineProps> & CompoundTimelineTypes = ({
  title,
  className,
  children,
}) => {
  const items = Children.toArray(children)

  return (
    <div className={bem(undefined, undefined, className)}>
      {title && <h2 className={bem("title")}>{title}</h2>}
      <ul>
        {items.map((child, index) =>
          cloneElement(child as ReactElement, {
            hideLine: items.length === index + 1,
          }),
        )}
      </ul>
    </div>
  )
}

Timeline.Item = TimelineItem
