import { FC, HTMLAttributes } from "react"

import { Radio, RadioProps } from "../../radio"

import { BaseCardProps } from "../BaseCard"
import { Card } from "src/components/common/card"

export type RadioCardProps = HTMLAttributes<HTMLDivElement> &
  RadioProps &
  BaseCardProps & {
    cardClass?: string
    "data-cy"?: string
  }

/*
  TODO: This component has issues and should be redesigned.
  It's not correctly accessible — instead of being a styled input type radio, it's a bunch of divs
  Also, it takes passed styles that are repeated most of the times.
  Why don't we have a default styling that can be changed via props?
*/

export const RadioCard: FC<RadioCardProps> = ({
  cardClass,
  id,
  checked,
  classNames,
  children,
  "data-cy": dataCy,
  ...attributes
}) => {
  return (
    <Card
      variant="primary"
      selected={checked}
      className={cardClass}
      {...attributes}
    >
      <Radio id={id} classNames={classNames} checked={checked} data-cy={dataCy}>
        {children}
      </Radio>
    </Card>
  )
}
