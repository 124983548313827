import { Dispatch, SetStateAction, useState } from "react"

import {
  ConfirmModal,
  ConfirmModalSelectedOption,
  ConfirmModalType,
} from "../confirmModal/ConfirmModal"
import { useServiceConfiguratorValidationContext } from "../ServiceConfiguratorValidationContext"
import { checkServiceType } from "src/helpers/checkServiceType"
import { filterOutExpressAppointment } from "src/helpers/filterOutExpressAppointment"
import {
  hasExpressAppointment,
  isExpressAppointmentSelected,
} from "src/helpers/isExpressAppointmentSelected"
import { isReplacementCarSelected } from "src/helpers/isReplacementCarSelected"
import {
  mergeServiceFlowData,
  useServiceFlowData,
} from "src/states/serviceFlow"
import garageActions from "src/states/serviceFlow/garageActions"
import serviceCategoriesAction from "src/states/serviceFlow/serviceCategoriesAction"
import { ServiceCategory } from "src/states/serviceFlow/types"

export const useCategorySubmit = ({
  currentCategory,
  setCurrentCategory,
  onSuccess,
}: {
  currentCategory: ServiceCategory | null
  setCurrentCategory: Dispatch<SetStateAction<ServiceCategory | null>>
  onSuccess: () => void
}) => {
  const { serviceCategories: selectedCategories } = useServiceFlowData()
  const { validate } = useServiceConfiguratorValidationContext()

  const isReplacementCarAlreadyRequested =
    isReplacementCarSelected(selectedCategories)

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false)

  const isCurrentCategoryWheelChangeService =
    currentCategory &&
    checkServiceType.isWheelChangeService(
      currentCategory.internalServiceCategoryId,
    )

  // Function to handle confirmation modal submission
  function onConfirmModalSubmit(
    _type: ConfirmModalType,
    value: ConfirmModalSelectedOption<ConfirmModalType>,
  ) {
    if (!currentCategory) {
      return
    }

    const isExpressAppointmentExcluded = value !== "expressAppointment"

    if (isExpressAppointmentExcluded) {
      serviceCategoriesAction.updateServiceCategoriesAndGarage(
        currentCategory,
        true,
      )
      garageActions.clearSelectedGarage()

      if (isCurrentCategoryWheelChangeService) {
        setCurrentCategory(filterOutExpressAppointment(currentCategory))
      }
    } else if (isCurrentCategoryWheelChangeService) {
      mergeServiceFlowData({ serviceCategories: [currentCategory] })
    }

    setIsConfirmModalOpened(false)
    onSuccess()
  }

  // Function to check if express appointment and other services are selected
  function isExpressAppointmentAndOtherServicesSelected() {
    const isExpressAppointmentServiceSelected =
      isExpressAppointmentSelected(selectedCategories) ||
      hasExpressAppointment(currentCategory)

    const isSeveralCategoriesSelected = selectedCategories?.some(
      (category) =>
        category?.internalServiceCategoryId !==
        currentCategory?.internalServiceCategoryId,
    )

    return isExpressAppointmentServiceSelected && isSeveralCategoriesSelected
  }

  // Function to handle category submission
  function onCategorySubmit() {
    if (!validate?.()) {
      return
    }

    if (isExpressAppointmentAndOtherServicesSelected()) {
      return setIsConfirmModalOpened(true)
    }

    serviceCategoriesAction.updateServiceCategoriesAndGarage(
      currentCategory,
      false,
      isReplacementCarAlreadyRequested,
    )

    onSuccess()
  }

  return {
    onCategorySubmit,
    confirmModal: isConfirmModalOpened ? (
      <ConfirmModal
        type="expressAppointment"
        open
        onOpenChange={setIsConfirmModalOpened}
        onSubmit={onConfirmModalSubmit}
      />
    ) : null,
  }
}
