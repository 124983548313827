import { RadioCard } from "src/components/common/baseCard/radioCard"
import { create } from "src/helpers/bem"

import styles from "./ConfirmModalOption.module.scss"

const bem = create(styles, "ConfirmModalOption")

export type ConfirmModalOption<T> = {
  value: T
  selected: boolean
  onSelect: (value: T) => void
  title: string
}

type ConfirmModalOptionProps<T> = ConfirmModalOption<T>

export const ConfirmModalOption = <T extends string>({
  value,
  selected,
  onSelect,
  title,
}: ConfirmModalOptionProps<T>) => {
  return (
    <RadioCard
      id={value}
      name={value}
      classNames={{
        container: bem(),
      }}
      checked={selected}
      onChange={() => {
        onSelect(value)
      }}
    >
      <div className={bem("title")}>{title}</div>
    </RadioCard>
  )
}
