import { useState } from "react"

import { Button } from "src/components/common/button"
import { Modal, ModalContent } from "src/components/common/modal"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./ConfirmModal.module.scss"
import { ConfirmModalOption } from "./ConfirmModalOption"

const bem = create(styles, "ConfirmModal")

export type ConfirmModalType = "expressAppointment"
export type ConfirmModalSelectedOption<T> = T | "other"

type ConfirmModalProps<T extends ConfirmModalType> = {
  type: T
  open: boolean
  onOpenChange: (open: boolean) => void
  onSubmit: (type: T, value: ConfirmModalSelectedOption<T>) => void
}

export const ConfirmModal = <T extends ConfirmModalType>({
  type,
  open,
  onOpenChange,
  onSubmit,
}: ConfirmModalProps<T>) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.service.confirmModal[
      type as keyof typeof messages.pages.services.service.confirmModal
    ]

  const [selectedOption, setSelectedOption] =
    useState<ConfirmModalSelectedOption<T>>()

  const options: ConfirmModalOption<ConfirmModalSelectedOption<T>>[] = [
    {
      value: type,
      selected: selectedOption === type,
      onSelect: setSelectedOption,
      title: translations.option1,
    },
    {
      value: "other",
      selected: selectedOption === "other",
      onSelect: setSelectedOption,
      title: translations.option2,
    },
  ]

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <ModalContent
        hideCloseButton
        variant="sm"
        title={translations.title}
        bodyClassName={bem()}
        data-cy="ConfirmModal"
      >
        <p className={bem("description")}>{translations.description}</p>
        <p className={bem("question")}>{translations.question}</p>

        <div className={bem("options")}>
          {options.map((option) => (
            <ConfirmModalOption key={option.value} {...option} />
          ))}
        </div>

        <div className={bem("buttons")}>
          <Button
            variant="primary"
            className={bem("button")}
            onClick={() => {
              if (selectedOption) onSubmit(type, selectedOption)
            }}
            disabled={!selectedOption}
            data-cy="ConfirmModalSubmit"
          >
            {translations.buttons.confirm}
          </Button>
          <Button
            variant="secondary"
            className={bem("button")}
            onClick={() => onOpenChange(false)}
          >
            {translations.buttons.cancel}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
