import { FC, useEffect, useState } from "react"

import { CopyText, CopyTextProps } from "../copyText"
import { Icon, IconName } from "src/components/common/icon"

import { Modal, ModalContent, ModalTrigger } from "src/components/common/modal"
import { Tooltip } from "src/components/common/tooltip"
import { create } from "src/helpers/bem"

import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

import styles from "./ClickableBanner.module.scss"

const bem = create(styles, "ClickableBanner")

export type ClickableBannerProps = {
  bannerTitle?: string
  modalTitle?: string
  tooltipText?: string
  icon?: IconName | null
  modalContent: CopyTextProps
  source?: "productPage"
}

export const ClickableBanner: FC<ClickableBannerProps> = ({
  bannerTitle,
  icon,
  tooltipText,
  modalTitle,
  modalContent,
  source,
}) => {
  const [openModal, setOpenModal] = useState(false)

  const openOrCloseModal = () => {
    setOpenModal(!openModal)
  }

  useEffect(() => {
    if (openModal) {
      switch (source) {
        case "productPage":
          sendTrackingEvent.services({
            gaEvent: {
              action: "product_banner_click",
            },
          })
        default:
          return
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  return (
    <>
      <Modal open={openModal} onOpenChange={openOrCloseModal}>
        <ModalTrigger>
          <div className={bem()}>
            <div className={bem("icon-title-container")}>
              {icon && <Icon className={bem("icon")} name={icon} />}
              <span className={bem("title")}>{bannerTitle}</span>
            </div>
            {tooltipText && !openModal && (
              <Tooltip
                side="top"
                trigger={<Icon className={bem("icon")} name="info" />}
                content={tooltipText}
              />
            )}
          </div>
        </ModalTrigger>
        {openModal && (
          <ModalContent
            variant="md"
            title={modalTitle}
            highlightedHeader
            noPadding
            bodyClassName={bem("modal")}
          >
            <CopyText {...modalContent} />
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
