import { FC } from "react"

import { hasAdditionalServices } from "../../helpers/hasAdditionalServices"
import { BrakeDiscAndPadToggle } from "../brakeDiscAndPadToggle"
import { CategoryAdditionals } from "../categoryAdditionals"
import { CategoryServicesToggle, CarSide } from "../categoryServicesToggle"

import { CategoryWithPrice } from "../categoryWithPrice"
import { Card } from "src/components/common/card"
import {
  AdditionalService,
  Service,
} from "src/helpers/autoservice-core-backend/types"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { useTranslation } from "src/hooks/translation"

import { ServiceCategory } from "src/states/serviceFlow/types"

import styles from "./DefaultCategory.module.scss"

const bem = create(styles, "DefaultCategory")

export type BrakeChangeCategoryCategoryProps = {
  category: Category
  serviceDisabled?: boolean
  selectedCategoryData: ServiceCategory
  onServiceToggle: (service: Service) => void
  onCategoryChange: (id: string) => void
  onAdditionalServiceToggle: (
    additionalService: AdditionalService,
    checked: boolean,
  ) => void
}

export const BrakeChangeCategory: FC<BrakeChangeCategoryCategoryProps> = ({
  category,
  serviceDisabled,
  selectedCategoryData,
  onServiceToggle,
  onCategoryChange,
  onAdditionalServiceToggle,
}) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.brakesChange

  const hasAdditionals = hasAdditionalServices(category.additionalServices)
  const selectedService = selectedCategoryData.service

  const servicesData: { [key: string]: { title: string; carSide: CarSide } } = {
    BRAKE_PAD_CHANGE_BOTH: {
      title: translations.services.complete,
      carSide: "complete",
    },
    BRAKE_PAD_CHANGE_FRONT: {
      title: translations.services.front,
      carSide: "top",
    },
    BRAKE_PAD_CHANGE_BACK: {
      title: translations.services.back,
      carSide: "bottom",
    },
    BRAKE_DISC_AND_PAD_CHANGE_BOTH: {
      title: translations.services.complete,
      carSide: "complete",
    },
    BRAKE_DISC_AND_PAD_CHANGE_FRONT: {
      title: translations.services.front,
      carSide: "top",
    },
    BRAKE_DISC_AND_PAD_CHANGE_BACK: {
      title: translations.services.back,
      carSide: "bottom",
    },
  }

  const selectedCardSide =
    servicesData[selectedService?.internalServiceId ?? ""]?.carSide

  const servicesToggleItems = category.services.map((service) => ({
    value: service.internalServiceId ?? "",
    children: servicesData[service.internalServiceId ?? ""]?.title ?? "",
  }))

  const serviceIcon = checkServiceType.isBrakeDiscChangeService(
    category.internalServiceCategoryId,
  )
    ? "bremsenwechsel"
    : "bremsbelagwechsel"

  return (
    <div className={bem()}>
      <CategoryWithPrice
        category={category}
        serviceDisabled={serviceDisabled}
      />

      <h2 className={bem("title")}>{translations.title}</h2>

      <BrakeDiscAndPadToggle
        title={translations.subtitle}
        value={selectedCategoryData.internalServiceCategoryId}
        onValueChange={onCategoryChange}
      />

      <div className={bem("variants")}>
        <h3 className={bem("subtitle")}>{translations.selectArea}</h3>

        <CategoryServicesToggle
          category={category}
          servicesToggleItems={servicesToggleItems}
          selectedService={selectedService}
          onServiceToggle={onServiceToggle}
          serviceIcon={serviceIcon}
          selectedCarSide={selectedCardSide}
        />

        <Card variant="info" noMargin className={bem("infoBox")}>
          <h2 className={bem("infoBox__title")}>
            {translations.infoBox.title}
          </h2>
          <p className={bem("infoBox__description")}>
            {translations.infoBox.description}
          </p>
        </Card>
      </div>

      {hasAdditionals && (
        <CategoryAdditionals
          categoryName={category.title}
          additionalServices={category.additionalServices}
          selectedAdditionalServices={selectedCategoryData.additionalServices}
          onAdditionalServiceToggle={onAdditionalServiceToggle}
        />
      )}
    </div>
  )
}
