import { FC, PropsWithChildren } from "react"

import { Icon } from "../icon"
import { create } from "src/helpers/bem"

import styles from "./TimelineItem.module.scss"

const bem = create(styles, "TimelineItem")

export type TimelineItemStatusType = "completed" | "failed" | "pending"

export type TimelineItemProps = PropsWithChildren<{
  state: TimelineItemStatusType
  title?: string
  hideLine?: boolean
}>

export const TimelineItem: FC<TimelineItemProps> = ({
  title,
  state,
  children,
  hideLine,
}) => {
  const modifiers = {
    [`is-${state}`]: true,
  }

  return (
    <li className={bem()}>
      <div className={bem("status")}>
        <div className={bem("status__icon")}>
          <div className={bem("status__icon__head", modifiers)}>
            {state === "completed" && <Icon name="navArrowRight" />}
            {state === "failed" && <Icon name="cancel" />}
          </div>
          {!hideLine ? <div className={bem("status__icon__tail")} /> : null}
        </div>
        <div className={bem("status__info")}>
          {title && <div className={bem("status__info__title")}>{title}</div>}
          <div className={bem("status__info__details")}>{children}</div>
        </div>
      </div>
    </li>
  )
}
