import { FC, useState } from "react"

import { Button } from "src/components/common/button"
import { Modal, ModalContent } from "src/components/common/modal"
import { CategoryServiceItemWithCheckbox } from "src/components/pages/services/service/categoryCard/categoryServices/CategoryServiceItemWithCheckbox"
import { useCategorySelectionTrackingEvents } from "src/components/pages/services/service/categoryCard/hooks/useCategorySelectionTrackingEvents"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./HuAuForElectricVehicle.module.scss"

const bem = create(styles, "HuAuForElectricVehicle")

export const HuAuForElectricVehicle: FC = () => {
  const { messages, formatMessageRaw } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.huau.smokeBlowTest

  const [open, setOpen] = useState(false)

  const { trackHuAuSmokeBlowTestClick } = useCategorySelectionTrackingEvents()

  const onClick = () => {
    trackHuAuSmokeBlowTestClick()
    setOpen(true)
  }

  const formatListItem = (text: string) =>
    formatMessageRaw(text, {
      bold: (title: string) => (
        <span className={bem("bold")} key={title}>
          {title}
        </span>
      ),
    })

  return (
    <>
      <CategoryServiceItemWithCheckbox
        key="huau_for_electric_vehicle"
        service={{ title: translations.name }}
        isSelected={false}
        handleServiceToggle={onClick}
      />

      <Modal open={open} onOpenChange={setOpen}>
        <ModalContent title={translations.title} variant="sm">
          <div className={bem()}>
            <p>{translations.options}</p>
            <div className={bem("list")}>
              <p>{formatListItem(translations.option1)}</p>
              <p>{formatListItem(translations.option2)}</p>
            </div>
            <p className={bem("bottom")}>{translations.inform}</p>
            <Button variant="tertiary" onClick={() => setOpen(false)}>
              {translations.back}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}
