import { FC } from "react"

import { Service } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"

import styles from "./CategoryServiceItem.module.scss"
import { CategoryServiceItemContent } from "./CategoryServiceItemContent"

const bem = create(styles, "CategoryServiceItem")

export type CategoryServiceItemProps = {
  service?: Service
  isSelected?: boolean
  disabled?: boolean
  recommended?: boolean
  handleServiceToggle: (service: Service) => void
}

export const CategoryServiceItem: FC<CategoryServiceItemProps> = ({
  service,
  isSelected,
  disabled,
  recommended,
  handleServiceToggle,
}) => {
  if (!service) {
    return null
  }

  const { internalServiceId } = service

  const modifiers = {
    "is-selected": !!isSelected && !disabled,
  }

  return (
    <div
      key={internalServiceId}
      className={bem(undefined, modifiers)}
      id={`category-${internalServiceId}`}
      onChange={() => handleServiceToggle(service)}
    >
      <CategoryServiceItemContent service={service} recommended={recommended} />
    </div>
  )
}
