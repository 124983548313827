import { FC, useEffect, useState } from "react"

import { hasAdditionalServices } from "../../helpers/hasAdditionalServices"
import { useServiceConfiguratorValidationContext } from "../../ServiceConfiguratorValidationContext"
import { CategoryAdditionals } from "../categoryAdditionals"
import { CategoryServicesWithCheckboxes } from "../categoryServices"
import { CategoryWithPrice } from "../categoryWithPrice"
import { Icon } from "src/components/common/icon"
import { InputError } from "src/components/common/input/InputError"
import { ToggleSwitch } from "src/components/common/toggleSwitch"
import { Tooltip } from "src/components/common/tooltip"
import {
  AdditionalService,
  Service,
} from "src/helpers/autoservice-core-backend/types"
import { Category } from "src/helpers/autoservice-core-backend/types"

import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { useIsCategorySelected } from "src/hooks/serviceSelection/useIsCategorySelected"
import { useTranslation } from "src/hooks/translation"
import { ServiceCategory } from "src/states/serviceFlow/types"

import styles from "./AirConditioningCategory.module.scss"
import defaultStyles from "./DefaultCategory.module.scss"

const defaultBem = create(defaultStyles, "DefaultCategory")
const bem = create(styles, "AirConditioningCategory")

type ClimaFluidType = "BEFORE_2017" | "AFTER_2017"
type ClimaFluidTypeOption = { value: ClimaFluidType; children: string }

export type AirConditioningCategoryProps = {
  category: Category
  serviceDisabled?: boolean
  selectedCategoryData: ServiceCategory
  onServiceToggle: (service: Service) => void
  onAdditionalServiceToggle: (
    additionalService: AdditionalService,
    checked: boolean,
  ) => void
}

export const AirConditioningCategory: FC<AirConditioningCategoryProps> = ({
  category,
  serviceDisabled,
  selectedCategoryData,
  onServiceToggle,
  onAdditionalServiceToggle,
}) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.airConditioning

  const isAirConditioningCategorySelected = useIsCategorySelected({
    serviceCategoryId: "AIR_CONDITIONING",
  })

  const [climaFluidType, setClimaFluidType] = useState<
    ClimaFluidType | undefined
  >(
    isAirConditioningCategorySelected
      ? checkServiceType.isAirConditioningAfter2017Service(
          selectedCategoryData.service?.internalServiceId,
        )
        ? "AFTER_2017"
        : "BEFORE_2017"
      : undefined,
  )

  const filteredCategory = {
    ...category,
    services: category.services.filter(
      (service) =>
        !checkServiceType.isAirConditioningAfter2017Service(
          service.internalServiceId,
        ),
    ),
  }

  const hasAdditionals = hasAdditionalServices(
    filteredCategory.additionalServices,
  )
  const hasMultipleServices = filteredCategory.services.length > 1
  const climaFluidTypeOptions: ClimaFluidTypeOption[] = [
    {
      value: "BEFORE_2017",
      children: translations.climaFluid.before2017,
    },
    {
      value: "AFTER_2017",
      children: translations.climaFluid.after2017,
    },
  ]

  const { isValid, setIsValid, showError } =
    useServiceConfiguratorValidationContext()
  const showClimaFluidTypeError = !isValid && showError

  const getServiceId = (id: string | undefined) => {
    const defaultId = id?.replace("_AFTER_2017", "")
    return climaFluidType === "AFTER_2017"
      ? `${defaultId}_${climaFluidType}`
      : defaultId
  }

  const onServiceChange = (service: Service) => {
    const id = service.internalServiceId
    const newValue =
      category.services.find(
        (service) => service.internalServiceId === getServiceId(id),
      ) ?? service

    onServiceToggle({
      ...service,
      ...newValue,
    })
  }

  const onClimaFluidTypeChange = (value: ClimaFluidType) => {
    setClimaFluidType(value)
  }

  const getIsServiceSelected = (id: string | undefined) => {
    const selectedId = selectedCategoryData.service?.internalServiceId

    return selectedId === getServiceId(id)
  }

  useEffect(() => {
    setIsValid?.(!!climaFluidType)

    if (selectedCategoryData.service) {
      onServiceChange(selectedCategoryData.service)
    }

    // Update only when climaFluidType is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [climaFluidType])

  return (
    <div className={defaultBem()}>
      <CategoryWithPrice
        serviceDisabled={serviceDisabled}
        category={filteredCategory}
      />

      <div className={bem("clima-fluid")}>
        <label className={bem("label")} htmlFor="climaFluidType">
          <p className={bem("title")}>{translations.climaFluid.title}</p>
          <Tooltip
            side="top"
            trigger={<Icon className={bem("info-icon")} name="info" />}
            content={translations.climaFluid.description}
          />
        </label>

        <ToggleSwitch
          variant="secondary"
          type="single"
          id="climaFluidType"
          value={climaFluidType}
          items={climaFluidTypeOptions}
          onValueChange={onClimaFluidTypeChange}
          data-error={showClimaFluidTypeError}
        />

        {showClimaFluidTypeError && (
          <InputError id="climaFluidTypeError">
            {translations.climaFluid.error}
          </InputError>
        )}
      </div>

      {hasMultipleServices && (
        <CategoryServicesWithCheckboxes
          category={filteredCategory}
          selectedService={selectedCategoryData.service}
          onServiceToggle={onServiceChange}
          getIsSelected={getIsServiceSelected}
        />
      )}

      {hasAdditionals && (
        <CategoryAdditionals
          categoryName={category.title}
          additionalServices={filteredCategory.additionalServices}
          selectedAdditionalServices={selectedCategoryData.additionalServices}
          onAdditionalServiceToggle={onAdditionalServiceToggle}
          packageAdditionalServiceIds={
            selectedCategoryData.service?.packageAdditionalServiceIds ?? []
          }
        />
      )}
    </div>
  )
}
