import { FC } from "react"

import { Timeline } from "src/components/common/timeline"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./InspectionDetails.module.scss"

const bem = create(styles, "InspectionDetails")

type InspectionDetailsProps = {
  hideAdditionalServicesText?: boolean
}

export const InspectionDetails: FC<InspectionDetailsProps> = ({
  hideAdditionalServicesText = false,
}) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.inspection.additionalInfo

  return (
    <div className={bem()}>
      <h2 className={bem("title")}>{translations.title}</h2>
      <p className={bem("description")}>
        {hideAdditionalServicesText
          ? translations.description
          : `${translations.description} ${translations.additional}`}
      </p>
      <Timeline>
        <Timeline.Item state="pending">
          {translations.timeline.step1}
        </Timeline.Item>
        <Timeline.Item state="pending">
          {translations.timeline.step2}
        </Timeline.Item>
        <Timeline.Item state="pending">
          {translations.timeline.step3}
        </Timeline.Item>
      </Timeline>
    </div>
  )
}
