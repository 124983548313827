import { Category } from "src/helpers/autoservice-core-backend/types"
import { checkServiceType } from "src/helpers/checkServiceType"
import { ServiceCategory } from "src/states/serviceFlow/types"

type GetCategory = (
  allCategoriesContentfullData: Category[],
  categoryContentfulData: Category,
  isBrakePadCategorySelected: boolean,
) => Category

const getCategory: GetCategory = (
  allCategoriesContentfullData: Category[],
  categoryContentfulData: Category,
  isBrakePadCategorySelected: boolean,
): Category => {
  let category: Category = categoryContentfulData

  // If selected service is BRAKE_PAD_CHANGE we need to update the Contentful category
  // Cause initially the category is BRAKE_DISC_CHANGE
  if (
    isBrakePadCategorySelected &&
    checkServiceType.isBrakeDiscChangeService(
      categoryContentfulData.internalServiceCategoryId,
    )
  ) {
    category =
      allCategoriesContentfullData?.find((cat) =>
        checkServiceType.isBrakePadChangeService(cat.internalServiceCategoryId),
      ) ?? categoryContentfulData
  }

  return category
}

type GetCurrentCategoryData = (
  selectedCategories: ServiceCategory[] | undefined,
  allCategoriesContentfullData: Category[],
  categoryContentfulData: Category,
  isBrakePadCategorySelected: boolean,
) => {
  /** Initial Contentful category data */
  category: Category
  /** Initial category state */
  currentCategoryData: ServiceCategory
  /** Is category preselected */
  isSelected: boolean
}

export const getCurrentCategoryData: GetCurrentCategoryData = (
  selectedCategories = [],
  allCategoriesContentfullData,
  categoryContentfulData,
  isBrakePadCategorySelected,
) => {
  const category: Category = getCategory(
    allCategoriesContentfullData,
    categoryContentfulData,
    isBrakePadCategorySelected,
  )

  const currentServiceCategory = selectedCategories?.find(
    (service) =>
      service.internalServiceCategoryId === category.internalServiceCategoryId,
  )

  // To convert Proxy object into plain object
  const selectedServiceCategory: ServiceCategory | null = currentServiceCategory
    ? JSON.parse(JSON.stringify(currentServiceCategory))
    : null

  const { services, ...serviceCategory } = categoryContentfulData

  return {
    category,
    currentCategoryData: selectedServiceCategory ?? {
      ...serviceCategory,
      service: services[0],
      additionalServices: [],
    },
    isSelected: !!selectedServiceCategory,
  }
}
