import { FC } from "react"

import { Category } from "src/helpers/autoservice-core-backend/types"

import {
  AdditionalService,
  Service,
} from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"

import { ServiceCategory } from "src/states/serviceFlow/types"

import { AirConditioningCategory } from "./categoryContent/AirConditioningCategory"
import { BrakeChangeCategory } from "./categoryContent/BrakeChangeCategory"
import { DefaultCategory } from "./categoryContent/DefaultCategory"
import { ShockAbsorberCategory } from "./categoryContent/ShockAbsorberCategory"
import styles from "./CategoryDetails.module.scss"

const bem = create(styles, "CategoryDetails")

export type CategoryDetailsProps = {
  category: Category
  selectedCategoryData: ServiceCategory
  onServiceToggle: (service: Service) => void
  onCategoryChange: (id: string) => void
  onAdditionalServiceToggle: (
    additionalService: AdditionalService,
    checked: boolean,
  ) => void
}

export const CategoryDetails: FC<CategoryDetailsProps> = (props) => {
  const { internalServiceCategoryId } = props.selectedCategoryData

  const categoryCards: { [key: string]: FC<CategoryDetailsProps> } = {
    BRAKE_DISC_CHANGE: BrakeChangeCategory,
    BRAKE_PAD_CHANGE: BrakeChangeCategory,
    DEFAULT: DefaultCategory,
    SHOCK_ABSORBER_CHANGE: ShockAbsorberCategory,
    AIR_CONDITIONING: AirConditioningCategory,
  }

  const CategoryComponent =
    categoryCards[internalServiceCategoryId ?? "DEFAULT"] ??
    categoryCards["DEFAULT"]

  return (
    <div className={bem()}>
      <CategoryComponent {...props} />
    </div>
  )
}
