import {
  Root,
  Item,
  ToggleGroupSingleProps as RadixToggleGroupSingleProps,
  ToggleGroupMultipleProps as RadixToggleGroupMultipleProps,
  ToggleGroupItemProps as RadixToggleGroupItemProps,
} from "@radix-ui/react-toggle-group"
import { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./ToggleSwitch.module.scss"

const bem = create(styles, "ToggleSwitch")

export type ToggleGroupProps =
  | RadixToggleGroupSingleProps
  | RadixToggleGroupMultipleProps
export type ToggleItem = RadixToggleGroupItemProps

export type ToggleSwitchProps = ToggleGroupProps & {
  variant: "primary" | "secondary" | "tertiary"
  items: ToggleItem[]
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  variant,
  items,
  className,
  ...otherProps
}) => {
  return (
    <Root
      className={bem(undefined, { [variant]: true }, className)}
      {...otherProps}
    >
      {items.map(({ className: itemClassName, ...otherItemProps }) => (
        <Item
          key={otherItemProps.value}
          data-cy={otherItemProps.value}
          className={bem("item", { [variant]: true }, itemClassName)}
          {...otherItemProps}
        />
      ))}
    </Root>
  )
}
