import { FC } from "react"

import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./HUAUFootnote.module.scss"

const bem = create(styles, "HUAUFootnote")

export const HUAUFootnote: FC<{}> = () => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.service.categoryCard.huau

  return <div className={bem()}>{translations.footnote}</div>
}
