import { FC } from "react"

import { Tag } from "src/components/common/tag"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { useTranslation } from "src/hooks/translation"

import styles from "./CategoryWithPrice.module.scss"

const bem = create(styles, "CategoryWithPrice")

export type CategoryWithPriceProps = {
  category: Category
  serviceDisabled?: boolean
}

export const CategoryWithPrice: FC<CategoryWithPriceProps> = ({
  category,
  serviceDisabled,
}) => {
  const { messages } = useTranslation()
  const translation = messages.pages.services.flow.services

  const isInspectionService = checkServiceType.isInspectionService(
    category.internalServiceCategoryId,
  )

  if (!serviceDisabled && !isInspectionService) return null

  return (
    <div className={bem()}>
      {serviceDisabled && (
        <Tag variant="primary">{translation.availableSoon}</Tag>
      )}
      {isInspectionService && (
        <Tag variant="secondary">{translation.priceAndTimeOnDemand}</Tag>
      )}
    </div>
  )
}
