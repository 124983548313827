import { Category, Service } from "src/helpers/autoservice-core-backend/types"
import { checkServiceType } from "src/helpers/checkServiceType"
import { ServiceCategory } from "src/states/serviceFlow/types"

export const getSelectedService = (
  selectedCategory: ServiceCategory,
  categoryContentfulData: Category,
): Service => {
  if (
    checkServiceType.isBrakeChangeService(
      selectedCategory.internalServiceCategoryId,
    )
  ) {
    const serviceId = selectedCategory.service?.internalServiceId
    const carSide = serviceId?.substring(serviceId?.lastIndexOf("_"))
    const serviceVariant = categoryContentfulData.services.find(
      (service) => carSide && service.internalServiceId?.includes(carSide),
    )

    return serviceVariant ?? categoryContentfulData.services[0]
  }

  return categoryContentfulData.services[0]
}
