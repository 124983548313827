import Router from "next/router"
import { FC, PropsWithChildren, useEffect, useRef, useState } from "react"

import {
  ClickableBanner,
  ClickableBannerProps,
} from "src/components/cck/elements/clickableBanner"
import { Button, ButtonProps } from "src/components/common/button"
import { ROUTES, serviceFlowSteps } from "src/config"
import { screenBreakpoints } from "src/const/screenBreakpoints"

import {
  AdditionalService,
  Category,
  Service,
} from "src/helpers/autoservice-core-backend/types"

import { create } from "src/helpers/bem"

import { checkAdditionalServiceType } from "src/helpers/checkAdditionalServiceType"
import { Media } from "src/helpers/media"

import { useIsMounted } from "src/hooks/isMounted"

import { useIsCategorySelected } from "src/hooks/serviceSelection/useIsCategorySelected"
import { useTranslation } from "src/hooks/translation"
import { useServiceFlowData } from "src/states/serviceFlow"

import serviceCategoriesAction from "src/states/serviceFlow/serviceCategoriesAction"
import { ServiceCategory } from "src/states/serviceFlow/types"

import { CategoryDetails } from "./categoryCard"
import { useCategorySelectionTrackingEvents } from "./categoryCard/hooks/useCategorySelectionTrackingEvents"

import { getCurrentCategoryData } from "./helpers/getCurrentCategoryData"
import { getSelectedService } from "./helpers/getSelectedService"
import { useCategorySubmit } from "./hooks/useCategorySubmit"

import styles from "./ServiceConfigurator.module.scss"

const bem = create(styles, "ServiceConfigurator")

export type ServiceConfiguratorProps = PropsWithChildren<{
  category: Category
  categories: Category[]
  title?: string
  productBanner?: ClickableBannerProps
  onSuccess: (categoryAddedOrUpdated?: boolean) => void
  classNames?: string
  deleteButtonProps?: ButtonProps
  location?: "boldTeaser"
  onOpenChange?: (open: boolean) => void
}>

export const ServiceConfigurator: FC<ServiceConfiguratorProps> = ({
  category: categoryContentfulData,
  categories: allCategoriesContentfullData,
  title,
  productBanner,
  onSuccess,
  classNames,
  deleteButtonProps,
  children,
  location,
  onOpenChange,
}) => {
  const { messages, formatMessageRaw } = useTranslation()
  const translations = messages.pages.services.flow.service

  const isMounted = useIsMounted()

  const { serviceCategories: allSelectedCategories } = useServiceFlowData()
  const isBrakePadCategorySelected = useIsCategorySelected({
    serviceCategoryId: "BRAKE_PAD_CHANGE",
  })

  const {
    trackServiceSelection,
    trackAdditionalServiceSelection,
    trackSubmit,
  } = useCategorySelectionTrackingEvents()

  const [currentCategory, setCurrentCategory] =
    useState<ServiceCategory | null>(null)
  const categoryRef = useRef<Category>(categoryContentfulData)
  const isCategorySelectedRef = useRef(false)

  const { onCategorySubmit, confirmModal } = useCategorySubmit({
    currentCategory,
    setCurrentCategory,
    onSuccess: () => {
      trackSubmit(currentCategory?.service)

      onSuccess(true)
    },
  })

  useEffect(() => {
    if (isMounted) {
      const { category, currentCategoryData, isSelected } =
        getCurrentCategoryData(
          allSelectedCategories,
          allCategoriesContentfullData,
          categoryContentfulData,
          isBrakePadCategorySelected,
        )

      categoryRef.current = category
      isCategorySelectedRef.current = isSelected

      setCurrentCategory(currentCategoryData)
    }
    // We update the state after when localStarage is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])

  if (!currentCategory) {
    return null
  }

  const onClickDeleteButton = () => {
    if (isCategorySelectedRef.current) {
      serviceCategoriesAction.removeCategory(
        categoryContentfulData.internalServiceCategoryId,
      )

      onSuccess()
    } else {
      if (location === "boldTeaser") {
        onOpenChange?.(false)
      } else {
        Router.push(ROUTES.serviceFlow({ flowStep: serviceFlowSteps.service }))
      }
    }
  }

  const onServiceChange = (service: Service) => {
    setCurrentCategory((state) => ({
      ...currentCategory,
      additionalServices:
        state?.additionalServices.filter(
          (additionalService) =>
            !!additionalService.internalAdditionalServiceId &&
            !service.packageAdditionalServiceIds?.includes(
              additionalService.internalAdditionalServiceId,
            ),
        ) ?? [],
      service,
    }))

    trackServiceSelection(service)
  }

  const onAdditionalServiceChange = (
    additionalService: AdditionalService,
    checked: boolean,
  ) => {
    /**
     * The replacement car additional service has a special case that it should be selected only once
     * When it is selected for one service it is automatically selected for others as it is related to booking
     * So if it is removed from one service it should be removed from the one it is originally selected in UI
     * This piece of code handles this case
     * code starts here
     */
    const isReplacementCarAdditionalService =
      checkAdditionalServiceType.isReplacementCar(
        additionalService.internalAdditionalServiceId,
      )

    if (isReplacementCarAdditionalService && !checked) {
      serviceCategoriesAction.updateReplacementCarAdditionalService(
        additionalService,
        !checked,
      )
    }
    /** Code ends here for handling the replacement car special case */
    setCurrentCategory({
      ...currentCategory,
      additionalServices: checked
        ? // add additional service
          [...currentCategory.additionalServices, additionalService]
        : // remove additional service
          currentCategory.additionalServices.filter(
            (service) =>
              service.internalAdditionalServiceId !==
              additionalService.internalAdditionalServiceId,
          ),
    })

    trackAdditionalServiceSelection(additionalService, checked)
  }

  const onCategoryChange = (categoryId: string) => {
    const newCategory = allCategoriesContentfullData?.find(
      (category) => category.internalServiceCategoryId === categoryId,
    )
    if (newCategory) {
      categoryRef.current = newCategory

      setCurrentCategory({
        ...newCategory,
        additionalServices: currentCategory.additionalServices,
        service: getSelectedService(currentCategory, newCategory),
      })
    }
  }

  return (
    <div className={bem(undefined, undefined, classNames)} id="configurator">
      <Media minWidth={screenBreakpoints.md}>
        {title && <h2 className={bem("title")}>{title}</h2>}
      </Media>

      {productBanner && (
        <ClickableBanner {...productBanner} source="productPage" />
      )}

      <CategoryDetails
        key={categoryContentfulData.internalServiceCategoryId}
        category={categoryRef.current}
        selectedCategoryData={currentCategory}
        onServiceToggle={onServiceChange}
        onCategoryChange={onCategoryChange}
        onAdditionalServiceToggle={onAdditionalServiceChange}
      />

      {children}

      <div className={bem("buttons")}>
        <Button
          size="lg"
          variant="primary"
          data-cy="confirm-service"
          onClick={onCategorySubmit}
          className={bem("button")}
        >
          {isCategorySelectedRef.current
            ? translations.buttons.confirmChanges
            : formatMessageRaw(translations.buttons.confirmSelection, {
                service: () => categoryContentfulData.title,
              })}
        </Button>

        <Button
          size="lg"
          variant="secondary"
          data-cy="delete-service"
          onClick={onClickDeleteButton}
          className={bem("button")}
          {...deleteButtonProps}
        >
          {isCategorySelectedRef.current
            ? translations.buttons.deleteSelection
            : translations.buttons.back}
        </Button>
      </div>

      {confirmModal}
    </div>
  )
}
