import { FC } from "react"

import { Radio } from "src/components/common/radio"
import { Service } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"

import styles from "./CategoryServiceItem.module.scss"
import { CategoryServiceItemContent } from "./CategoryServiceItemContent"

const bem = create(styles, "CategoryServiceItem")

export type CategoryServiceItemWithCheckboxProps = {
  internalServiceCategoryId?: string
  service: Service
  isSelected?: boolean
  disabled?: boolean
  recommended?: boolean
  handleServiceToggle: (service: Service) => void
}

export const CategoryServiceItemWithCheckbox: FC<
  CategoryServiceItemWithCheckboxProps
> = ({
  internalServiceCategoryId,
  service,
  isSelected,
  disabled,
  recommended,
  handleServiceToggle,
}) => {
  if (!service) {
    return null
  }

  const { internalServiceId } = service

  const modifiers = {
    "is-selected": !!isSelected && !disabled,
  }

  return (
    <Radio
      key={internalServiceId}
      classNames={{
        container: bem(undefined, modifiers),
      }}
      name={`category-${internalServiceCategoryId}`}
      id={`category-${internalServiceId}`}
      data-cy={internalServiceId}
      onChange={() => handleServiceToggle(service)}
      checked={isSelected && !disabled}
      disabled={disabled}
    >
      <CategoryServiceItemContent service={service} recommended={recommended} />
    </Radio>
  )
}
